<template>
	<div v-if="show">
		<div class="card">
			<div class="card-header card-header-flex pb-2">
				<div class="w-100 mt-2">
					<div class="row">
						<div class="col-8">
							<h5 class="mt-3 ml-0 mr-3 mb-2">
								<strong>
									<template v-if="operation === null">
                                        {{$t('title.autoGeneratedInboundLogs')}}
                                        <span v-if="pagination.total" >({{pagination.total}})</span>
                                    </template>
									<template v-else>{{ $t(operationTitle) }}</template>
								</strong>
							</h5>
						</div>
						<div class="col-4 text-right">
							<div v-if="operation === null">
								<div class="mt-3">
									<!-- Using components -->
									<b-input-group class="mt-3">
										<b-form-input type="search" class="form-control form-control-sm"
										              :placeholder="$t('input.whatAreYouLookingFor')"
										              v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
									</b-input-group>
								</div>
							</div>
							<div v-else>
								<b-button variant="warning" size="sm" class="mt-3"
								          @click="handleOperationClose()"
								          v-b-tooltip.hover :title="$t('button.title.cancel')">
									<i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
								</b-button>
							</div>
						</div>
					</div><!-- /.row -->
				</div><!-- /.w-100 -->
			</div><!-- /.card-header -->
			<div class="card-body">
                <div class="auto-transfer-client-rule-log-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
						<template v-slot:cell(auto_transfer_client_rule_id)="{detailsShowing, item}">
							<a @click="toggleDetails(item)" class="d-inline in-center">
								<i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
							</a>
							{{(item.auto_transfer_client_rule || {}).group_id}}
						</template>
						<template v-slot:cell(client_id)="record">
							{{((record.item.auto_transfer_client_rule || {}).client || {}).company_name}}
						</template>
						<template v-slot:cell(next_client_id)="record">
							{{((record.item.auto_transfer_client_rule || {}).next_client || {}).company_name}}
						</template>
						<template v-slot:cell(created_at)="record">
							{{ $global.utcDateToLocalDate(record.item.created_at) }}
						</template>
						<template v-slot:row-details="{ item }">
							<b-card>
								<b-list-group flush>
									<b-list-group-item>
										<strong>{{$t('column.vinNumber')}}</strong>:
										<span v-for="order in item.transferred_orders">{{order.vin_number}}, </span>
									</b-list-group-item>
								</b-list-group>
							</b-card>
						</template>
                    </b-table><!-- /.b-table -->
                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.auto-transfer-client-rule-log-table -->
                <div class="auto-transfer-client-rule-log-operation"></div><!--/.auto-transfer-client-rule-log-operation-->
				<div class="filter-container">
					<a-drawer
						placement="left"
						:width="'360px'"
						:wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
						:closable="false"
						@close="filters.visible = !filters.visible"
						:visible="!operation && filters.visible"
						:zIndex="10"
						:title="$t('title.advanceFilters')"
					>
						<form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
							<b-row>
								<b-col sm="12">
									<b-form-group
											:label="$t('input.fromAddedDate')"
											label-for="fromDate">
										<b-form-datepicker placeholder="" id="fromDate" v-model="filters.from_date"
										                   class="mb-2"></b-form-datepicker>
									</b-form-group>
								</b-col><!--/b-col-->
								<b-col sm="12">
									<b-form-group
											:label="$t('input.toAddedDate')"
											label-for="toDate">
										<b-form-datepicker placeholder="" id="toDate" v-model="filters.to_date"
										                   class="mb-2"></b-form-datepicker>
									</b-form-group>
								</b-col><!--/b-col-->
							</b-row>
							<div class="drawer-footer">
								<b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
								          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
									{{$t('button.close')}}
								</b-button>
								<b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
								          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
									{{$t('button.reset')}}
								</b-button>
								<b-button size='sm' variant="primary" button="submit" type="filled"
								          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
									{{$t('button.apply')}}
								</b-button>
							</div><!-- /.drawer-footer -->
						</form>
					</a-drawer>
				</div><!-- /.filter-container -->
			</div><!-- /.card-body-->
		</div><!-- /.card -->
	</div>
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'

    const FORM_STATE = {};

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.autoClientTransferRule'),
            key: 'auto_transfer_client_rule_id',
            sortable: true,
            sortKey: 'auto_transfer_client_rule_id',
        },
        {
            label: self.$t('column.currentClient'),
            key: 'client_id',
            sortable: false,
        },
        {
            label: self.$t('column.nextClient'),
            key: 'next_client_id',
            sortable: false,
        },
        {
            label: self.$t('column.delayInDays'),
            key: 'delay_in_days',
            sortable: true,
            sortKey: 'delay_in_days',
        },
        {
            label: self.$t('column.orders'),
            key: 'orders',
            sortable: true,
            sortKey: 'orders',
        },
        {
            label: self.$t('column.createdAt'),
            key: 'created_at',
            sortable: true,
        },
    ];

    export default {
        mixins: [ListingMixin],
        components: {
            Datepicker,
            Treeselect
        },
        data() {
            return {
                operationTitle: 'title.autoTransferClientLogs',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'auto/transfer/client/rules/logs',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {},
                show: true,
            }
        },
        mounted() {
            this.$title = this.$t('topBar.navigations.modules.autoTransferClientRuleLogs')

            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {

            }
        },
        methods: {
			toggleDetails(row) {
				this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
			},
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = null
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
			toggleDetails(row) {
				this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
			},
            async handleSubmit() {

            },
            hasListAccess() {
                return this.$global.hasPermission('autotransferclientrulesview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
			handleResetFilterClick() {
				this.filters = {...FILTER_STATE}
				this.isFilterApplied = 'reset'
				this.handleResetClick()
				this.loadList(this.listQueryParams)
			},
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
